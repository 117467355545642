import React, { createContext } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { SidebarProvider } from 'src/contexts/SidebarContext';

import 'nprogress/nprogress.css';
import './styles/index.scss';
import App from './App';

import RootStore from './store';

const rootStore = new RootStore();

export const Context = createContext(rootStore);

// import { SidebarProvider } from './contexts/SidebarContext';

ReactDOM.render(
  <HelmetProvider>
    <SidebarProvider>
      <Context.Provider value={{rootStore}}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Context.Provider>
    </SidebarProvider>
  </HelmetProvider>,
  document.getElementById('root')
);
