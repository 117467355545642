// import { makeAutoObservable, toJS } from "mobx";
import { makeAutoObservable } from "mobx";
import _ from "lodash";
import axios from "axios";

class TreeCategories {
	constructor () {
		makeAutoObservable(this);
		this.fetchFlatTree();
	}

	categoryCaption = '';

	nodeSelect = {};
	
	categoryId = '';

	hierarchicalTree = [];

	selectedNodeID = 0;

	newNodeName = '';

	isLoadingTree = false;

	flatTree = [];

	fetchFlatTree = async () => {
		const typeResponse = 'asList';
		const { data } = await axios.post('https://zefir.store/php/catalog/getCategories.php', { typeResponse });
		// data.unshift({ id: 0, name: "Категорії", title: "Категорії одягу", parentId: null, sort: 0, key: 0 });
		this.setFlatTree(data);
		// this.flatToHierarchy(this.flatTree);
		// const hirarchicalTree = flatToHierarchy(toJS(this.flatTree));
		// this.setHierarchicalTree(hirarchicalTree);
		// console.log('flatTree >', toJS(this.flatTree));
	}

	flatToHierarchy (flat) {
		let roots = []; // things without parentId
	
		// make them accessible by id on this map
		let all = {};
	
		[...flat].forEach(function(item) {
			all[item.id] = { ...item };
		});
	
		// connect childrens to its parentId, and split roots apart
		Object.keys(all).forEach(function(id) {
			let item = all[id];
			if (item.parentId === null) {
				roots.push(item);
			} else if (item.parentId in all) {
				let p = all[item.parentId];
				if (!("children" in p)) {
					p.children = [];
				}
				p.children.push(item);
				p.children.sort((a, b) => a.sort > b.sort ? 1 : -1);
			}
		});
	
		// done!
		// return roots;
		this.setHierarchicalTree(roots);
	};

	setFlatTree (updatedTree) {
		updatedTree.unshift({ id: 0, name: "Категорії", title: "Категорії одягу", parentId: null, sort: 0, key: 0 });
		this.flatTree = updatedTree;
		this.flatToHierarchy(this.flatTree);
		// this.setHierarchicalTree(this.flatTree);
		// console.log(this.flatTree);
	};

	setSelectedNodeID (id) {
		this.selectedNodeID = id;
	}

	onSelect = async (selectedKey, info) => {
		this.setSelectedNodeID(info.node.id);
		if (info.node.id > 0) {
			this.setCategoryCaption(info.node.title);
			const { data } = await axios.post('https://zefir.store/php/catalog/getOptionsCategory.php', { id: this.selectedNodeID });
			console.log('result >', data);
		} else {
			this.setCategoryCaption('');
		}
		console.log(info.node.id);
	};

	handleAddNode = async () => {
		if (this.newNodeName.length === 0) {
			console.log('Введіть назву категорії');
			return;
		}
		if (this.selectedNodeID >= 0 && this.newNodeName.length > 0) {
			// let key = Math.random();
			const selectedNode = this.flatTree.find((node) => node.id === this.selectedNodeID);
			if (selectedNode) {
				const siblings = this.flatTree.filter((node) => Number(node.parentId) === Number(selectedNode.id));
				const maxSortValue = siblings.reduce((prev, cur) => cur?.sort > prev.sort ? cur : prev , { sort: -1 }).sort;
				let newNodeObj = {
					name: this.newNodeName,
					parentId: this.selectedNodeID,
					title: this.newNodeName,
					sort: maxSortValue + 1
				};
				// this.setNewNodeName('');
				this.newNodeName = '';
				const { data } = await axios.post('https://zefir.store/php/catalog/saveCategories.php', { typeRequest: 'addNode', newNodeObj });
				// this.setFlatTree([...this.flatTree, newNodeObj]);
				console.log('data >', data);
				this.setFlatTree(data);
			}
		} else {
			console.log("Виберіть гілку");
		}
  	};

	setHierarchicalTree (newHierarichalTree) {
		// console.log('newHierarichalTree >', newHierarichalTree);
		this.hierarchicalTree = newHierarichalTree;
	}

	setCategoryCaption = (value) => {
		this.categoryCaption = value;
		// console.log(value);
	}

	// setNewNodeName (newName) {
	// 	this.newNodeName = newName;
	// }

	setCategoryID (value) {
		this.categoryId = value;
		// console.log(value);
	}

	setTreeData (newTreeData) {
		this.treeData = newTreeData;
	}

	handleOnChangeNewTitle = (value) => {
		// let newNodeName = e.target.value.trim();
		this.newNodeName = value;
		// console.log(value);
	};

	handleRenameBranch = async () => {
		// const updatedTree = this.flatTree.map((node) => {
		//   if (node.id === this.selectedNodeID) {
		// 	return { ...node, title: this.categoryCaption };
		//   }
		//   return node;
		// });
		try {
			const updateNode = {
				id: this.selectedNodeID,
				title: this.categoryCaption
			};
			const { data } = await axios.post('https://zefir.store/php/catalog/saveCategories.php', { typeRequest: 'updateNodeTitle', updateNode });
			this.setFlatTree(data);
			// this.setFlatTree(updatedTree);
			this.setCategoryCaption('');
		} catch (e) {
			console.error('Не вдалося змінити назву категорії ;(', e);
		}
		
	};

	handleDeleteNode = async () => {
		if (!this.selectedNodeID) {
		  console.log("Select a node to delete");
		} else {
			try {
				const deleteNode = {
					id: this.selectedNodeID
				};
				const { data } = await axios.post('https://zefir.store/php/catalog/saveCategories.php', { typeRequest: 'deleteNode', deleteNode });
				this.setFlatTree(data);
				// const updatedTree = this.flatTree.filter((node) => node.id !== this.selectedNodeID);
				// this.setFlatTree(updatedTree);
				this.setSelectedNodeID(0);
				this.setCategoryCaption('');
			} catch (e) {
				console.error('Не вдалося видалити категорію ;(', e);
			}
		  
		}
	};

	handleMoveNodeUp = () => {
		if (!this.selectedNodeID) {
		  console.log("Select a node to move");
		} else {
		  const selectedNode = this.flatTree.find((node) => node.id === this.selectedNodeID);
		  if (selectedNode) {
				const siblings = this.flatTree.filter((node) => node.parentId === selectedNode.parentId);
				const nodeIndex = siblings.findIndex((node) => node.id === this.selectedNodeID);
				if (nodeIndex > 0) {
					const updatedSiblings = [...siblings];
					const tempSort = updatedSiblings[nodeIndex].sort;
					updatedSiblings[nodeIndex].sort = updatedSiblings[nodeIndex - 1].sort;
					updatedSiblings[nodeIndex - 1].sort = tempSort;
					const updatedTree = this.flatTree.map((node) =>
					updatedSiblings.find((sibling) => sibling.id === node.id) || node
					);
					this.setFlatTree(updatedTree);
					this.sortFlatTree();
					// console.log(updatedTree);
				}
		  }
		}
	};

	handleSaveTree = async () => {
		const categories = this.flatTree.filter(item => item.id !== 0);
		// console.log('categories >>>', categories);
		// const { data } = await axios.post('https://zefir.store/php/catalog/saveCategories.php', { categories });
		const { data } = await axios.post('https://zefir.store/php/catalog/saveCategories.php', { categories });
		console.log('data >', data);
		this.flatTree(data);
	};
	
	handleMoveNodeDown = () => {
		if (!this.selectedNodeID) {
			console.log("Select a node to move");
		} else {
			const selectedNode = this.flatTree.find((node) => node.id === this.selectedNodeID);
			if (selectedNode) {
				const siblings = this.flatTree.filter((node) => node.parentId === selectedNode.parentId);
				const nodeIndex = siblings.findIndex((node) => node.id === this.selectedNodeID);
				if (nodeIndex < siblings.length - 1) {
					const updatedSiblings = [...siblings];
					const tempSort = updatedSiblings[nodeIndex].sort;
					updatedSiblings[nodeIndex].sort = updatedSiblings[nodeIndex + 1].sort;
					updatedSiblings[nodeIndex + 1].sort = tempSort;
					const updatedTree = this.flatTree.map((node) =>
						updatedSiblings.find((sibling) => sibling.id === node.id) || node
					);
					this.setFlatTree(updatedTree);
					this.sortFlatTree();
					// console.log(updatedTree);
				}
			}
		}
	};

	sortFlatTree () {
		const sortedTree = _.sortBy(this.flatTree, ["parentId", "sort"]);
		this.setFlatTree(sortedTree);
	};



}

export default TreeCategories;
