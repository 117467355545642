import TreeCategories from "./tree-categories";

class RootStore {
    treeCategoriesStore;

    constructor () {
        this.treeCategoriesStore = new TreeCategories(this);
    }
}

export default RootStore;
